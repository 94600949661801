<template>
    <div>
      <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
          <div>
          <validation-observer ref="informationDocument">
              <b-row style="display:block;">
                  <b-col cols="6">
                  <b-form-group
                      label="Title"
                      label-for="h-Title"
                      label-cols-md="2"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="Title"
                          rules="required"
                      >
                          <b-form-input
                              id="h-Title"
                              type="text"
                              placeholder="Title"
                              maxlength="50"
                              v-model="title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                  </b-col>
                  <b-col cols="6">
                  <b-form-group
                      label="Description"
                      label-for="h-Description"
                      label-cols-md="2"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="Description"
                          rules="required"
                      >
                          <b-form-textarea
                              id="h-Description"
                              maxlength="500"
                              placeholder="Description"
                              v-model="discription"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                        label="Attachment"
                        label-for="h-Attachment"
                        label-cols-md="2"
                    >
                        <b-form-file
                            v-model="selectNewAttchemnt"
                            :disabled="onlyRead || isSpinner"
                            @input="attachmentUpload()"
                        />
                        <div class="mt-1">
                            <strong v-if="selectNewAttchemnt == null">{{ attachment ? `Selected file: ${displayFunction(attachment.name)}` : '' }}</strong>
                            <strong v-else>{{ selectNewAttchemnt ? `Selected file: ${selectNewAttchemnt.name}` : '' }}</strong>
                            <img v-if="($route.params && $route.params.id && selectNewAttchemnt != null) || ($route.params && $route.params.id && attachment != null)" src="../../../assets/images/erflog/Delete.png" @click="deleteButtonCalled" alt="attachment" style="width: 15px;height: 15px;margin-left: 10px;cursor: pointer;"/>
                        </div>
                        <small class="text-danger">{{ errorMessage }}</small>
                    </b-form-group>
                  </b-col>
              </b-row>
              <div class="sendBtn" style="text-align:left;">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="updateDocument"
                      :disabled="onlyRead || isSpinner"
                  >
                      Update
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="$router.push({name: 'notice-mgnt'})"
                      :disabled="onlyRead || isSpinner"
                  >
                  Cancel
                  </b-button>
              </div>
          </validation-observer>
          </div>
    </div>
  </template>
  
<script>
import BCardCode from '@core/components/b-card-code'
import { required,min,max } from 'vee-validate/dist/rules'
import {
BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormRadio,BFormFile,BFormSelect,BSpinner,BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
extend('required',required);
extend('min',min);
extend('max',max);
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebaseTimeStamp from "firebase";
export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormRadio,
        BFormFile,
        BFormSelect,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            attachment: null,
            selectNewAttchemnt: null,
            title:"",
            discription: "",
            options: [],
            errorMessage: "",
            isSpinner: false,
            onlyRead:false,
            editImage: false,
        }
    },
    created() {
        if(this.$route.params.id) {
                this.isSpinner = true;
                db.collection(dbCollections.NOTICES).doc(this.$route.params.id).get().then((res)=>{
                        if(res.exists) {
                            this.title = res.data().title;
                            this.discription = res.data().discription;
                            this.attachment = (res.data().attachmentName != '' && res.data().attachment != '') ? {name:res.data().attachmentName,docUrl:res.data().attachment} : null;
                            this.docFileObj = (res.data().attachmentName != '' && res.data().attachment != '') ? {name:res.data().attachmentName,docUrl:res.data().attachment} : null;
                            this.isSpinner = false;
                        } else {
                                this.isSpinner = false;
                        }
                }).catch((err)=>{
                    this.isSpinner = false;
                    console.error(err)
                })
        }
        this.$root.$on('stopSpinner',()=>{
            this.isSpinner = false;
        });
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Notice Management"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
    },
    methods: {
        deleteButtonCalled() {
            if(this.isSpinner) {
                return;
            }
            this.selectNewAttchemnt=null
            this.attachment=null
        },
        updateDocument(){
            try {             
                var self = this;
                self.$refs.informationDocument.validate().then((valid) => {
                    if(self.$route.params.id) {
                        if (valid && self.errorMessage == '') {
                            this.isSpinner = true;
                            if(this.selectNewAttchemnt != null || this.attachment != null) {
                                if(self.editImage) {
                                        if(self.docFileObj != null) {
                                            uploadImageFile.removeImage('noticeDocument',self.docFileObj.name,()=>{})
                                        }
                                        uploadImageFile.uploadImageFile('noticeDocument',self.selectNewAttchemnt.name,self.selectNewAttchemnt,(resp)=>{
                                            let obj = {
                                                updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                                title: self.title,
                                                discription: self.discription,
                                                attachment: resp.imageUrl,
                                                attachmentName: resp.imageName,
                                            }
                                            db.collection(dbCollections.NOTICES).doc(self.$route.params.id).update(obj).then(()=>{
                                                this.$toast({
                                                    component: ToastificationContent,
                                                    position: 'top-right',
                                                    props: {
                                                        title: 'Notice updated successfully',
                                                        icon: 'CoffeeIcon',
                                                        variant: 'success',                               
                                                    },
                                                })
                                                this.$router.push({name: 'notice-mgnt'});
                                                this.isSpinner = false;
                                            }).catch((err)=>{
                                                console.error(err)
                                            })
                                        })
                                } else {
                                    let obj = {
                                        updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                        title: self.title,
                                        discription: self.discription,
                                        attachment: self.attachment.docUrl,
                                        attachmentName: self.attachment.name,
                                    }
                                    db.collection(dbCollections.NOTICES).doc(self.$route.params.id).update(obj).then(()=>{
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'Notice updated successfully',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                        })
                                        this.$router.push({name: 'notice-mgnt'});
                                        this.isSpinner = false;
                                    }).catch((err)=>{
                                        console.error(err)
                                    })
                                }
                            } else {
                                let obj = {
                                        updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                        title: self.title,
                                        discription: self.discription,
                                        attachment: '',
                                        attachmentName: '',
                                    }
                                    db.collection(dbCollections.NOTICES).doc(self.$route.params.id).update(obj).then(()=>{
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'Notice updated successfully',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                        })
                                        this.$router.push({name: 'notice-mgnt'});
                                        this.isSpinner = false;
                                    }).catch((err)=>{
                                        console.error(err)
                                    })
                            }
                        } else {
                            this.isSpinner = false;
                        }
                    }
                });
            } catch (error) {
                this.isSpinner = false;
                console.error(error);
            }
        },
        displayFunction(name) {
            let withExte = name.split('.')[0];
            let fName = withExte.split('_')[0];
            if(withExte.split('_').length > 2){
                return withExte.split('_').slice(0,withExte.split('_').length-1).join('_') +'.'+ name.split('.')[1];
            } else {
                return fName +'.'+ name.split('.')[1];
            }
        },
        attachmentUpload(){
            try {
                this.errorMessage = "";
                let extension = this.selectNewAttchemnt.name.split('.').pop();
                let allowedExtensions = ['jpg','png','jpeg','pdf','doc','docx','xls','xlsx','txt','ppt','pptx'];
                if (!allowedExtensions.includes(extension.toLowerCase())) {
                    this.errorMessage = 'Uploaded file is not a valid. Allowed files are ' + allowedExtensions.join(', ');
                }
                else if (this.selectNewAttchemnt.size >= 20000000 ) {
                    this.errorMessage = 'File should be less than 20 mb'
                }
                this.editImage = true;
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<style>

</style>